if (!window.console) {
    console = {};
    console.log = function () { };
}

var curLang = $('html').attr('lang'),
    isExperienceEditor = $('.pagemode-edit').length;

(function ($) {

    $(function () {
        if ($('img.fixheight').length) {
            $('img.fixheight').matchHeight();
        }

        if ($('.fixheight:not(img):not(div):not(h2)').length) {
            $('.fixheight').matchHeight();
        }

        if ($('li.level-2').length) {
            $('li.level-2').matchHeight();
        }

        if ($('div.fixheight').length) {
            $('div.fixheight').matchHeight();
        }

        if ($('div.highlight-items').length) {
            $('div.highlight-items').matchHeight();
            $('div.highlight-image').matchHeight();
        }

        if ($('.modal-body .contact-card div.name').length) {
            //  $('.modal-body .contact-card').matchHeight();
            //  $('.modal-body div.name').matchHeight();
            //  $('.modal-body div.position').matchHeight();
        }

        if ($('h2.fixheight').length) {
            $('h2.fixheight').matchHeight();
        }

        if ($('.manifesto').length) {

            $('.manifesto').on('click', function (e) {
                e.preventDefault();
                $(this).toggleClass('open')
                $('.mani-content').toggleClass('open')
            });

            $('.manifesto').on('keyup', function (e) {
                e.preventDefault();

                if (e.which == 13 || e.which == 32) {
                    $(this).trigger("click");
                }
            });
        }

        if ($('.manifesto').length) {
            $('.closecollapse').on('click', function (e) {
                e.preventDefault();
                $('.manifesto').toggleClass('open')
                $('.mani-content').toggleClass('open')
            });
        }
    });
})(jQuery);

function throttle(callback, limit) {
    var wait = false;
    return function () {
        if (!wait) {
            callback.call();
            wait = true;
            setTimeout(function () {
                wait = false;
            }, limit);
        }
    }
}

function conainerHeight($containerAlignment) {
    $containerAlignment.each(function () {
        var $teaserAlignment = $(this).find('.teaser-alignment');

        if ($teaserAlignment.height() > $(this).height()) {
            $containerAlignment.css('min-height', $teaserAlignment.outerHeight());
        } else {
            $containerAlignment.css('min-height', '');
        }
    });
}

(function ($) {

    $(function () {
        var delay = 200,
            $containerAlignment = $('.container-alignment');


        if ($containerAlignment.length) {

            conainerHeight($containerAlignment);

            $(window).on("resize", function () {
                throttle(conainerHeight($containerAlignment), delay)
            });
        }

    });
})(jQuery);


(function ($) {

    $(function () {
        var $form = $('.newsletter-form'),
            $input = $form.find('[type="email"]:visible');


        if ($form.length) {
            $input.attr('placeholder', $input.val()).val('');
        }

    });
})(jQuery);


/**
* Content order will be switched on Mobile only if there's images that
* need to be displayed before its text
*/
(function ($) {

    $(function () {
        var $flexOrderImage = $('.flex-order-image');

        if ($flexOrderImage.length) {
            $flexOrderImage.each(function () {
                if (!$(this).find('img').length) {
                    $(this).removeClass('flex-order-image');
                }
            });
        }
    });
})(jQuery);


$(document).ready(function () {

    $(".el-btn").on('click', function (e) {
        var heightitem = $(this).parent().parent('.el-item').height();
        e.preventDefault();
        if ($(this).hasClass('open')) {
            $(this).parent().next('.el-collapses').toggleClass('open');
            $(this).toggleClass('open')
            $(this).parent().toggleClass('open')
            $('.block-grid-item').height('auto').css
        } else {
            $('.el-collapses').removeClass('open');
            $('.el-btn').removeClass('open')
            $('.el-btn').parent().removeClass('open')
            $('.block-grid-item').height('auto').css

            $(this).parent().next('.el-collapses').toggleClass('open');
            $(this).toggleClass('open')
            $(this).parent().toggleClass('open')
            if ($(this).parent().next('.el-collapses').hasClass('open')) {
                var heightcollapse = $(this).parent().next('.el-collapses').find('.coll-content').height();
            } else {
                var heightcollapse = 0;
            }
            var totalheight = heightitem + heightcollapse + 180
            $(this).parent().parent().parent('.block-grid-item').height(totalheight).css
        }
    });

    $('.el-collapses .closecollapse').on('click', function (e) {
        $(this).parent().parent().find('.el-btn').trigger("click");
    });
});

(function ($) {
    $(function () {
        $('.code').each(function () {
            var encoded = document.createTextNode($.trim($(this)[0].innerHTML));
            $(this).html(encoded);
            var editor = ace.edit($(this).attr('id'));
            editor.setFontSize(13);
            editor.getSession().setMode("ace/mode/html");
            //$(this).html(encoded);
        });
    });
})(jQuery);

(function ($) {
    $(function () {
        /**
         * Add class .injectMe to a link element
         * ex: <img src="/img/logo.svg" class="injectMe" />
         */
        var mySVGsToInject = document.querySelectorAll('img.injectMe');

        for (var i = 0, j = mySVGsToInject.length; i < j; i++) {
            SVGInjector(mySVGsToInject[i]);
        }
    });
})(jQuery);


(function ($) {
    $(function () {
        /**
         * Add class .focusOnMyAnchor to a link element
         * ex : <a href = "#content" class = "focusOnMyanchor" > Skip to content </a>
         */
        $('.focusOnMyAnchor').on('click', function () {
            var myAnchor = $(this).attr('href');
            $('' + myAnchor + '').attr('tabIndex', '0');
            $('' + myAnchor + '').focus();
        });
    });
})(jQuery);


$(window).on("load", function () {
    /**
     * Sticky button used on Careers section
     */
    var $mainHeader = $('.main-header'),
        $anchorNav = $('.anchor-nav-container');

    if ($('.sticky-button').length && $('html').hasClass('desktop')) {
        $('.sticky-button').parent().parent().addClass('sticky-div');
        $('.main-footer').css("margin-top", "100px");

        var htmlHeight = $(window).height();
        var scrollTop = $(window).scrollTop();
        var elementOffset = $('.sticky-div').offset().top;
        var distance = (elementOffset - scrollTop);

        var buttonHeight = $('.sticky-div').outerHeight();
        var topDistance = (distance - htmlHeight) + buttonHeight;

        $('.sticky-div').affix({
            offset: {
                top: topDistance,
                bottom: $('footer').height() + 110
            }
        });
    }

    $mainHeader.affix({
        offset: {
            top: 100,
            bottom: 'auto'
        }
    })

    Respond.to({
        'media': '(max-width: 1199px)',
        'namespace': '1200_scroll',
        'fallback': 'else',
        'if': function () {
            $(window).off('scroll.up');
            $mainHeader.removeClass('scroll-up');

            if ($anchorNav.length) {
                $anchorNav.removeClass('scroll-up');
            }
        },
        'else': function () {
            var iScrollPos = 0;

            $(window).on('scroll.up', function () {
                var iCurScrollPos = $(this).scrollTop();

                if (iCurScrollPos == 0) {
                    $mainHeader.removeClass('scroll-up');
                    if ($anchorNav.length) {
                        $anchorNav.removeClass('scroll-up');
                    }
                } else {
                    if (iCurScrollPos > iScrollPos && $mainHeader.hasClass('scroll-up')) {
                        $mainHeader.removeClass('scroll-up');

                        if ($anchorNav.length) {
                            $anchorNav.removeClass('scroll-up');
                        }
                    } else if (!$mainHeader.hasClass('scroll-up') && iCurScrollPos < iScrollPos) {
                        $mainHeader.addClass('scroll-up');

                        if ($anchorNav.length) {
                            $anchorNav.addClass('scroll-up');
                        }
                    }
                }
                iScrollPos = iCurScrollPos;
            });
        }
    });

    if ($anchorNav.length) {
        $anchorNav.affix({
            offset: {
                top: $anchorNav.offset().top,
                bottom: 'auto'
            }
        })
    }
});

(function ($) {
    $(function () {
        /**
         * Patch hotfix BUG #6555
         * To hide tab if in global languages
         */
        if ($('#sectorsLoc').length) {
            var currentPathname = window.location.pathname;
            var currentPathname = currentPathname.toLowerCase();

            if (currentPathname.indexOf("-gl/") >= 0) {
                $('.nav.nav-tabs.nopadding').hide();
            }
        }
    });
})(jQuery);

/**
* Footer Columns without an orange title
* Checks if the short-title on Sitecore Content Editor = "no title"
*/
(function ($) {
    $(".mf__link[title*='no title']").css("visibility", "hidden");
})(jQuery);


/**
* Carousel Fixes
* Clone carousel nav for desktop to vertically align the prev/nev to the title buttons when h2 is used
*/

/*$(document).ready(function () {
    var $carouselNav = $('.owl-nav');

    if ($carouselNav.length) {
        $carouselNav.each(function () {

            var $navClone = $(this).clone(true, true),
                $parent = $(this).closest('.project-list-container, .services-sectors-slider, .featured-publications-warpper, .carousel-container'),
                $row = $parent.prev('.row'),
                $container = $row.find('.carousel-nav-container'),
                $rowContent = $row.find('div:first-child :first-child').length ? $row.find('div:first-child :first-child') : $row;

            if ($container.length) {
                $container.append($navClone);
                $(this).addClass('hide-nav');
            }
        })

        Respond.to({
            'media': '(max-width: 1199px)',
            'namespace': '1200_owlNav',
            'fallback': 'else',
            'if': function () {

                $carouselNav.each(function () {
                    var $navClone = $(this).clone(true),
                        $parent = $(this).closest('.project-list-container, .services-sectors-slider, .featured-publications-warpper, .carousel-container'),
                        $row = $parent.prev('.row'),
                        $container = $row.find('.carousel-nav-container'),
                        $rowContent = $row.find('div:first-child :first-child').length ? $row.find('div:first-child :first-child') : $row;

                    if ($container.length) {
                        $container.css('height', '50px');
                    }
                })


                $(window).off('resize.scroll');
            },
            'else': function () {


                $carouselNav.each(function () {

                    var $navClone = $(this).clone(true, true),
                        $parent = $(this).closest('.project-list-container, .services-sectors-slider, .featured-publications-warpper, .carousel-container'),
                        $row = $parent.prev('.row'),
                        $container = $row.find('.carousel-nav-container'),
                        $rowContent = $row.find('div:first-child :first-child').length ? $row.find('div:first-child :first-child') : $row;

                    if ($container.length) {
                        $container.height($rowContent.height());

                        $(window).on('resize.scroll', function () {
                            $container.height($rowContent.height());
                        })
                    }
                })

            }
        });
    }
});
*/

(function ($) {
    $(function () {
        var $countryModal = $('#countryModal');

        if ($countryModal.length) {
            $countryModal.modal('show');
            var buttons = $countryModal.find('[data-language]');
            buttons.on('click', function () {
                var value = $(this).data('language');
                var name = 'geolocation';
                var days = 30;
                var date = new Date();
                date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
                var expires = "; expires=" + date.toUTCString();
                document.cookie = name + "=" + value + expires + "; path=/";
            });
        }
    });
})(jQuery);


/**
* Code for the Newsletter Forn
*/
(function ($) {

    $(function () {
        var delay = 200,
            $input = $('.newsletter-form input.text-box'),
            $button = $(".newsletter-form .form-submit-border"),
            temp;

        if ($input.length) {
            temp = $button.detach();
            $input.closest('.row').append(temp);
        }
    });
})(jQuery);

(function ($) {
    var $maxCharContainer = $("[data-max-char]"),
        isEditor = $('body.pagemode-edit').length;

    if ($maxCharContainer.length && !isEditor) {

        $maxCharContainer.each(function (i) {
            var text = $(this).text();
            var maxLength = $(this).data('max-char');
            var trimmedString = text.trim();
            var trimmedText = "";

            if (trimmedString.length >= maxLength) {
                trimmedString = trimmedString.substr(0, maxLength);
                trimmedText = "...";
            }

            $(this).html("<p>" + trimmedString + trimmedText + "</p>");

        });
    }

})(jQuery);


(function ($) {
    var $thumbnail = $('.thumbnail.m-b-1');

    if ($thumbnail.length) {

        $thumbnail.each(function (i) {
            var $caption = $(this).find('.caption'),
                $img = $(this).find('img'),
                that = $(this);

            $caption.css('width', $img.width());

            $(window).on('resize', function () {
                $caption.css('width', $img.width());
            });
        });
    }

})(jQuery);

//Override Coveo search results title.
//Update search result page title
UpdateSearchTitle = function (searchquery, searchcount) {
    var searchResultTitleTemplate = $("#search-result-title-template").val();
    var searchResultForTitleTemplate = $("#search-result-for-title-template").val();
    var titleToDisplay = searchResultTitleTemplate;

    titleToDisplay = String.format(searchResultForTitleTemplate, " <span>" + searchcount + "</span>");

    $("#search-result-title").html(titleToDisplay);
};

// Enable datalayer when click on an element that contain "data-wspdatalayer"
(function ($) {
    $(function () {

        // make sure Google dataLayer is available before initialization
        if (typeof dataLayer === "undefined") {
            return;
        }

        $('[data-wspdatalayer]').on('click', function (e) {
            var values = $(this).data('wspdatalayer').replace(/\'/g, '"');
            values = JSON.parse(values);

            if ($(this).hasClass('CoveoSearchButton')) {
                values.searchTerm = $(this).closest('form').find('.CoveoQuerybox').val();
            } else if (!$(this).hasClass('btn')) {
                values.eventCategory = 'Link Click';
            }

            dataLayer.push(values);
        });
    });
})(jQuery);

// GA video tracker
(function ($) {
    $(function () {
        $('video').each(function () {

            var videoTitle,
                videoSrc,
                videoCurrentTime,
                videoDuration,
                videoPercentageCompleted;
            var videoStarted, videoTen, videoTwentyFive, videoFifty, videoSeventyFive = false;
            if (typeof dataLayer === "undefined" || typeof $(this).attr('autoplay') != 'undefined') {
                return;
            }

            $(this).find('source').each(function () {
                videoTitle = $(this).attr('src').split('/');
                videoTitle = videoTitle[videoTitle.length - 1].split('.')[0];
                videoSrc = $(this).attr('src');
                return true;
            });

            $(this).on('timeupdate', function () {
                videoCurrentTime = $(this)[0].currentTime;
                videoDuration = $(this)[0].duration;
                videoPercentageCompleted = Math.floor((videoCurrentTime / videoDuration) * 100);

                //0
                if ((!videoStarted) && (videoPercentageCompleted > 0)) {
                    videoStarted = true;
                    dataLayer.push({
                        'event': 'user_interaction',
                        'event_name': 'video_start',
                        'interaction': {
                            'video_current_time': videoCurrentTime,
                            'video_duration': videoDuration,
                            'video_percent': videoPercentageCompleted,
                            'video_provider': 'Sitecore',
                            'video_title': videoTitle,
                            'video_url': videoSrc,
                            'visible': true
                        }
                    });
                }
                // is 10
                if ((!videoTen) && (videoPercentageCompleted > 10)) {
                    videoTen = true;
                    dataLayer.push({
                        'event': 'user_interaction',
                        'event_name': 'video_progress',
                        'interaction': {
                            'video_current_time': videoCurrentTime,
                            'video_duration': videoDuration,
                            'video_percent': videoPercentageCompleted,
                            'video_provider': 'Sitecore',
                            'video_title': videoTitle,
                            'video_url': videoSrc,
                            'visible': true
                        }
                    });
                }
                // is 25
                if ((!videoTwentyFive) && (videoPercentageCompleted > 25)) {
                    videoTwentyFive = true;
                    dataLayer.push({
                        'event': 'user_interaction',
                        'event_name': 'video_progress',
                        'interaction': {
                            'video_current_time': videoCurrentTime,
                            'video_duration': videoDuration,
                            'video_percent': videoPercentageCompleted,
                            'video_provider': 'Sitecore',
                            'video_title': videoTitle,
                            'video_url': videoSrc,
                            'visible': true
                        }
                    });
                }
                // is 50
                if ((!videoFifty) && (videoPercentageCompleted > 50)) {
                    videoFifty = true;
                    dataLayer.push({
                        'event': 'user_interaction',
                        'event_name': 'video_progress',
                        'interaction': {
                            'video_current_time': videoCurrentTime,
                            'video_duration': videoDuration,
                            'video_percent': videoPercentageCompleted,
                            'video_provider': 'Sitecore',
                            'video_title': videoTitle,
                            'video_url': videoSrc,
                            'visible': true
                        }
                    });

                }
                // is 75
                if ((!videoSeventyFive) && (videoPercentageCompleted > 75)) {
                    videoSeventyFive = true;
                    dataLayer.push({
                        'event': 'user_interaction',
                        'event_name': 'video_progress',
                        'interaction': {
                            'video_current_time': videoCurrentTime,
                            'video_duration': videoDuration,
                            'video_percent': videoPercentageCompleted,
                            'video_provider': 'Sitecore',
                            'video_title': videoTitle,
                            'video_url': videoSrc,
                            'visible': true
                        }
                    });

                }
            }).on('ended', function () {
                videoCurrentTime = $(this)[0].currentTime;
                videoDuration = $(this)[0].duration;
                videoPercentageCompleted = Math.floor((videoCurrentTime / videoDuration) * 100);
                dataLayer.push({
                    'event': 'user_interaction',
                    'event_name': 'video_complete',
                    'interaction': {
                        'video_current_time': videoCurrentTime,
                        'video_duration': videoDuration,
                        'video_percent': videoPercentageCompleted,
                        'video_provider': 'Sitecore',
                        'video_title': videoTitle,
                        'video_url': videoSrc,
                        'visible': true
                    }
                });
            });

        });
    });
})(jQuery);



(function ($) {
    $(function () {
        var $tables = $('.table-responsive');

        if ($tables.length) {
            $tables.each(function () {
                var colspanCount = 0,
                    $rows = $(this).find('tr:not(:first-child)'),
                    $firstRow = $(this).find('tr:first-child');

                if ($rows.length) {
                    $firstRow.find('td').each(function () {
                        var $that = $(this);

                        $rows.find('td').eq($that.index()).attr('data-header', $that.text() + ": ");
                    });
                }
            })

        }
    });
})(jQuery);

(function ($) {
    $(function () {
        var $elBtn = $('.el-btn');
        if ($elBtn.length) {
            $(window).on("resize", function () {
                $elBtn.each(function () {
                    var totalheight,
                        heightcollapse,
                        heightitem;

                    if ($(this).hasClass('open')) {
                        heightitem = $(this).parent().parent('.el-item').height();
                        heightcollapse = $(this).parent().next('.el-collapses').find('.coll-content').height();
                        totalheight = heightitem + heightcollapse + 180;
                        $(this).closest('.el-container').css('height', totalheight);
                    }
                })
            });
        }
    });
})(jQuery);

function getContentHeight($el) {
    var numberOfLine = $el.attr('data-show-more'),
        firstChild = $el.find('.inner > *').first(),
        lineHeight = firstChild.css('line-height'),
        height = parseFloat(lineHeight) * numberOfLine > firstChild.height() ? firstChild.height() : parseFloat(lineHeight) * numberOfLine;

    return height;
}

function checkTransition($el, fn) {
    try {
        if ($.support.transition) {
            $el.one($.support.transition.end, function (e) {
                fn();
            });
        } else {
            fn();
        }
    } catch (e) {
        fn();
    }
}

(function ($) {
    $(function () {
        var $showMore = $('[data-show-more]');
        if ($showMore.length) {
            $showMore.each(function () {
                var $readMore = $(this).parent().find('.read-more'),
                    $readLess = $(this).parent().find('.read-less'),
                    $firstChild = $(this).find('.inner > *').first(),
                    $numberOfChilds = $(this).find('.inner > *').length,
                    $that = $(this),
                    height = getContentHeight($(this)),
                    $inner = $(this).find('.inner'),
                    busy = false;

                if (!$firstChild.length) {
                    $firstChild = $('<p>');
                    $firstChild.text($(this).find('.inner').text());
                    $(this).find('.inner').text('');
                    $(this).find('.inner').append($firstChild)
                    $firstChild = $(this).find('.inner > *').first();
                }

                $(this).data('height', $(this).height());
                $(this).css('height', getContentHeight($(this)));

                $readMore.on('click', function (e) {
                    var self = $(this);
                    e.preventDefault();

                    if (!busy) {
                        busy = true;
                        checkTransition($that, function () {
                            busy = false;
                            $that.removeClass('transitioning').css('height', 'auto');
                        });

                        $that.addClass('transitioning open');

                        setTimeout(function () {
                            $that.height($inner.outerHeight(true));
                        }, 60);

                        $readLess.show();
                        self.hide();
                    }
                });

                $readLess.on('click', function (e) {
                    e.preventDefault();
                    var self = $(this);

                    if (!busy) {
                        busy = true;
                        checkTransition($that, function () {
                            busy = false;
                            $that.removeClass('open transitioning');
                        });

                        $that.height($inner.outerHeight(true));

                        setTimeout(function () {
                            $that.addClass('transitioning').height(getContentHeight($that));
                        }, 60);

                        self.hide();
                        $readMore.show();
                    }
                });
            });

        }
    });
})(jQuery);

var ripplyScott = (function () {
    var circle = document.getElementById('js-ripple'),
        ripple = document.querySelectorAll('.js-ripple');

    function rippleAnimation(event, timing) {
        var tl = new TimelineMax();
        x = event.offsetX,
            y = event.offsetY,
            w = event.target.offsetWidth,
            h = event.target.offsetHeight,
            offsetX = Math.abs((w / 2) - x),
            offsetY = Math.abs((h / 2) - y),
            deltaX = (w / 2) + offsetX,
            deltaY = (h / 2) + offsetY,
            scale_ratio = Math.sqrt(Math.pow(deltaX, 2) + Math.pow(deltaY, 2));

        console.log('x is:' + x);
        console.log('y is:' + y);
        console.log('offsetX is:' + offsetX);
        console.log('offsetY is:' + offsetY);
        console.log('deltaX is:' + deltaX);
        console.log('deltaY is:' + deltaY);
        console.log('width is:' + w);
        console.log('height is:' + h);
        console.log('scale ratio is:' + scale_ratio);

        tl.fromTo(ripple, timing, {
            x: x,
            y: y,
            transformOrigin: '50% 50%',
            scale: 0,
            opacity: 1,
            ease: Linear.easeIn
        }, {
            scale: scale_ratio,
            opacity: 0
        });

        return tl;
    }

    return {
        init: function (target, timing) {
            var button = document.getElementById(target);

            button.addEventListener("click", function (event) {
                rippleAnimation.call(this, event, timing);
            });
        }
    };
})();

(function ($) {
    $(function () {

        var $buttons = $('.btn');

        if ($buttons.length) {

            $buttons.on('click', function (e) {

                var $div = $('<div/>', {
                    class: 'ripple-effect'
                }),
                    btnOffset = $(this).offset(),
                    xPos = e.pageX - btnOffset.left,
                    yPos = e.pageY - btnOffset.top,
                    $ripple;

                $ripple = $(".ripple-effect");

                $ripple.css("height", $(this).height());
                $ripple.css("width", $(this).height());
                $div.css({
                    top: yPos - ($ripple.height() / 2),
                    left: xPos - ($ripple.width() / 2),
                }).appendTo($(this));

                window.setTimeout(function () {
                    $div.remove();
                }, 1500);

            });
        }
    });
})(jQuery);

(function ($) {
    $(function () {
        var anchorNavBox = $('.anchor-nav-wrapper .col-md-8 a');
        if (anchorNavBox.length) {
            // console.log(anchorNavBox);
            anchorNavBox.on('click', function (e) {
                e.preventDefault();
                //   console.log(anchorNavBox);
                containerAnchorLink = $($(this).attr('href'));
                // console.log(containerAnchorLink);
                if (containerAnchorLink && containerAnchorLink.offset()) {
                    $("html, body").animate({ scrollTop: containerAnchorLink.offset().top - 150 + 'px' });
                }
            });
        }
    });
})(jQuery);


(function ($) {
    $(function () {

        var $keyNumber = $('.keynumber'),
            controller = new ScrollMagic.Controller();

        if ($keyNumber.length && !isExperienceEditor) {
            var numAnim

            $keyNumber.each(function (idx, itm) {
                var text = $(this).text().replace(/,/g, ""),
                    splittedText = text.split(' '),
                    isDecimal;

                for (var i = 0, j = splittedText.length; i < j; i++) {
                    var unit = "";

                    if (splittedText[i].indexOf(':') == -1 && !isNaN(parseFloat(splittedText[i]))) {
                        unit = splittedText[i].replace(parseFloat(splittedText[i]), '');
                        splittedText[i] = '<span class="count" data-count="' + parseFloat(splittedText[i]) + '">0</span>' + unit
                    }
                }

                $(this).html(splittedText.join(" "))
            });

            controller.addScene([
                new ScrollMagic.Scene({
                    triggerElement: '.key-number-group'
                }).on("enter", function (e) {
                    $keyNumber.each(function (idx, itm) {
                        var $span = $(this).find('.count');

                        $span.each(function (idx2, itm2) {
                            var isDecimal = $(this).data('count') != Math.floor($(this).data('count')) ? 1 : 0,
                                isDate = $(this).closest('.key-date').length;

                            numAnim = new CountUp($(this)[0], 0, $(this).data("count"), isDecimal, 2 + (idx * 0.5), {
                                useEasing: false,
                                useGrouping: true,
                                separator: '',
                                decimal: (isDate) ? '' : (curLang == 'fr') ? ',' : '.'
                            });

                            numAnim.start();
                        })
                    });
                })
            ]);
        }
    });
})(jQuery);

$(document).ready(function () {
    // Select all anchor tags with href attribute
    $('a[href]').on('click', function () {
        var href = this.getAttribute('href');
        if (/\.(pdf|doc|docx|xlsx|xls|ppt|pptx)$/i.test(href)) {
            $.ajax({
                url: document.location.origin + "/api/Sitecore/SitecoreGoals/CustomGoals?goalId=" + encodeURIComponent("{2F752118-9804-42F0-B956-F332AC2DDA7C}"),
                contentType: "application/json",
                type: "GET",
                context: this,
                success: function (data) {
                    console.log("Success", data);
                },
                error: function (data) {
                    console.log("error", data);
                }
            });
        }
    });
});